<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Service" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <!-- <va-input
                  label="Service page title *"
                  v-model="title"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.servicepage_title"
                  :error-messages="errors.servicepage_title"
                  @input="delete errors.servicepage_title"
                /> -->
                <!-- <div class="mb-3">
                  <span>title</span>
                  <ckeditor v-model="title" :editor="editor" :config="editorConfig"></ckeditor>
                </div> -->
                <va-input
                  label="title"
                  v-model="title"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.title"
                  :error-messages="errors.title"
                  @input="delete errors.title"
                />
                <div class="mb-3">
                  <span>text</span>
                  <ckeditor v-model="text" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="meta_title *"
                  v-model="meta_title"
                  :messages="['The recommended number of characters is 100']"
                  :error="!!errors.meta_title"
                  :error-messages="errors.meta_title"
                  @input="delete errors.meta_title"
                />
                <va-input
                  label="meta_description *"
                  v-model="meta_description"
                  :messages="['The recommended number of characters is 1000']"
                  :error="!!errors.meta_description"
                  :error-messages="errors.meta_description"
                  @input="delete errors.meta_description"
                />
                <va-input
                  label="meta_keywords *"
                  v-model="meta_keywords"
                  :messages="['The recommended number of characters is 100']"
                  :error="!!errors.meta_keywords"
                  :error-messages="errors.meta_keywords"
                  @input="delete errors.meta_keywords"
                />
                <va-input
                  label="seoText *"
                  v-model="seoText"
                  :messages="['The recommended number of characters is 1000']"
                  :error="!!errors.seoText"
                  :error-messages="errors.seoText"
                  @input="delete errors.seoText"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      title: '',
      text: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      seoImage: [],
      seoText: '',

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        servicepage_title: this.title,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/pages/services`, {
        main: {
          title: this.title,
          text: this.text,
        },
        seo: {
          meta_title: this.meta_title,
          meta_description: this.meta_description,
          meta_keywords: this.meta_keywords,
          image: this.seoImage,
          text: this.seoText,
        },
      })
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      // axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/admin/pages/validate/14`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      //   .then(response => {
      //     this.errors = response.data,
      //     if (!Object.keys(this.errors).length) {
      //       this.submit()
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     this.showToast('Validate Fetch Error')
      //   })
      this.submit()
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/pages/services`)
        .then(response => {
          console.log(response.data)
          this.title = response.data.main.title
          this.text = response.data.main.text

          this.meta_title = response.data.seo.meta_title
          this.meta_description = response.data.seo.meta_description
          this.meta_keywords = response.data.seo.meta_keywords
          this.seoImage = response.data.seo.image
          this.seoText = response.data.seo.text
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
